import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"
import logo from '../images/Main-Logo.png';
import './hamburger.min.css'

function Header({ siteTitle }) {

  const [isActive, toggleActive] = useState(false);
  
  if (typeof window !== 'undefined') {
    if (!window.localStorage.getItem('omtl_app') && window.innerWidth < 600) {
      setTimeout(() => {
        document.querySelector('html').style.overflowY = "hidden";
        document.querySelector('.popup-container').classList.add('active');
      }, 2500)
    }
  }

  return (
    <header>
      <Link to='/mysteries'>
        <div className={'logo'}>
          <span style={{fontFamily: 'Tiny Love', color: '#fff', marginRight:'10px'}}>On a</span>
          <img src={logo}/>
          <h1 style={{ display:'none' }}>{siteTitle}</h1>
        </div>
      </Link>
      <div className='menu'>
        <div className="mysteries">
          <Link activeClassName='active' to='/mysteries'>
            Mysteries
          </Link>
            <div className="mysteries-dropdown">
              <Link className="joyful-item" activeClassName='active' to='/mysteries/joyful'>Joyful</Link>
              <Link className="sorrowful-item" activeClassName='active' to='/mysteries/sorrowful'>Sorrowful</Link>
              <Link className="glorious-item" activeClassName='active' to='/mysteries/glorious'>Glorious</Link>
              <Link className="luminous-item" activeClassName='active' to='/mysteries/luminous'>Luminous</Link>
            </div>
        </div>
        <Link activeClassName='active' to='/about'>About</Link>
        <a target='_blank' href='https://etsy.com/shop/onamissiontolove'>Shop</a>
      </div>
      <div 
        className={(isActive)?"mobile-menu active": "mobile-menu"}
      >
        <Link activeClassName='active' to='/mysteries'>Mysteries</Link>
        <Link className="sub-item" activeClassName='active' to='/mysteries/joyful'>Joyful</Link>
        <Link className="sub-item" activeClassName='active' to='/mysteries/sorrowful'>Sorrowful</Link>
        <Link className="sub-item" activeClassName='active' to='/mysteries/glorious'>Glorious</Link>
        <Link className="sub-item" activeClassName='active' to='/mysteries/luminous'>Luminous</Link>
        <Link activeClassName='active' to='/about'>About</Link>
        <a className="shop" target='_blank' href='https://etsy.com/shop/onamissiontolove'>Shop</a>
      </div>
      <button 
        onClick={() => {toggleActive(!isActive)}} 
        className={(!isActive) ? "hamburger hamburger--squeeze" : "hamburger hamburger--squeeze is-active"}
        type="button">
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
