import { Link, graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from '../images/Main-Logo.png';
import InstagramPosts from './instagram.js';
import Img from "gatsby-image"

export default (data) => {
    let siteTitle = data.siteTitle;
    return (
        <>
        <InstagramPosts images={data.nodes}/>
        <footer>    
            <p>
                {siteTitle} © {new Date().getFullYear()}
            </p>
            <div className='footer-menu'>
                <Link activeClassName='active' to='/mysteries'>Mysteries</Link>
                <Link activeClassName='active' to='/about'>About</Link>
                <a target='_blank' href='https://etsy.com/shop/onamissiontolove'>Shop</a>
            </div>
        </footer>
    </>
    )
}
