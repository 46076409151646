import React from 'react';
import insta from '../../static/assets/images/insta.png';
import Img from "gatsby-image"

export default class Insta extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        console.log(this.props.images)
        return (
        <div className='wrapper-1080' style={{'marginTop':'50px'}}>
            <div style={{'width':'75px','margin':'15px auto'}}>
 		<a target='_blank' href="https://www.onamissiontolove.com/">
                <img style={{'width':'75px','margin':'15px auto','display':'block'}} src={insta}/>
		</a>
            </div>
		
        		<div align="center">This app is an offering of<i> On a Mission to Love.</i>
			</div>
           		<div align="center">Check out more rosary resources at <a target='_blank' href='https://www.onamissiontolove.com'>onamissiontolove.com</a>
			</div>
		
        </div>	
        )
    }
}